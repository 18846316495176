(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-competition/helpers/assets/helpers.js') >= 0) return;  svs.modules.push('/components/marketplace-competition/helpers/assets/helpers.js');
"use strict";


let resolveTimeZone;
let constants;
let DrawState;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  resolveTimeZone = trinidad.components.require('lb-utils', 'helper-formatdate').api.resolveTimeZone;
  constants = require('../../competition-handler/common/constants.es6');
  DrawState = require('../../../sport/tipsen-shared/assets/javascripts/tipsen-shared.es6').DrawState;
} else {
  resolveTimeZone = svs.components.lbUtils.helpers.formatDate.resolveTimeZone;
  constants = svs.components.marketplaceCompetition.competitionHandler.common.constants;
  DrawState = svs.components.sport.tipsenShared.DrawState;
}
const {
  competitionStates,
  competitionBackendState
} = constants;
const {
  UPCOMING,
  FINISHED,
  REG_OPEN_DRAW_DEFINED,
  REG_CLOSED_DRAW_DEFINED,
  REG_OPEN_DRAW_OPEN,
  REG_CLOSED_DRAW_OPEN,
  REG_OPEN_DRAW_LIVE,
  REG_CLOSED_DRAW_LIVE,
  REG_OPEN_DRAW_FINALIZED,
  REG_CLOSED_DRAW_FINALIZED,
  NO_COMPETITION
} = competitionStates;
const {
  CompetitionType,
  competitionUrls
} = svs.isServer ? require('../../constants/assets/javascripts/enum.es6') : svs.components.marketplaceCompetition.constants;
const resolveCompetitionName = competition => {
  if (competition) {
    return competition.compDesc;
  }
  return 'tävling';
};
const resolveCompetitionLogo = competitionId => {
  var _svs;
  return (_svs = svs) === null || _svs === void 0 || (_svs = _svs.cmsData) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 || (_svs = _svs.config) === null || _svs === void 0 || (_svs = _svs.competitions.find(comp => comp.competitionId === competitionId)) === null || _svs === void 0 ? void 0 : _svs.logo;
};
const resolveCompetitionBackground = competitionId => {
  var _svs2;
  return (_svs2 = svs) === null || _svs2 === void 0 || (_svs2 = _svs2.cmsData) === null || _svs2 === void 0 || (_svs2 = _svs2.data) === null || _svs2 === void 0 || (_svs2 = _svs2.config) === null || _svs2 === void 0 || (_svs2 = _svs2.competitions.find(comp => comp.competitionId === competitionId)) === null || _svs2 === void 0 ? void 0 : _svs2.header;
};
const resolveCompetitionUrl = compType => {
  switch (compType) {
    case CompetitionType.TipsSM:
      return competitionUrls.TIPS_SM_URL;
    case CompetitionType.TipsEM:
      return competitionUrls.TIPS_EM_URL;
    default:
      return '';
  }
};

const resolveCompetitionDrawStatus = (draw, competition) => {
  var _competition$details, _competition$details2;
  const currentTime = resolveTimeZone(new Date());
  const isBeforeStartTime = currentTime < resolveTimeZone(new Date(competition.details.startTime));
  const isBeforeRegistrationStartTime = currentTime < resolveTimeZone(new Date(competition.signupStartTime));
  const canRegister = !isBeforeRegistrationStartTime && (((draw === null || draw === void 0 ? void 0 : draw.drawNumber) || 0) < competition.details.drawNumberList[0] || ((draw === null || draw === void 0 ? void 0 : draw.drawNumber) || 0) === competition.details.drawNumberList[0] && draw.drawStateId < DrawState.Regclosed);
  const isComplete = ((_competition$details = competition.details) === null || _competition$details === void 0 ? void 0 : _competition$details.state) === competitionBackendState.COMPETITION_COMPLETE;
  const lastDrawNumber = competition.details.drawNumberList.slice(-1).pop();
  const isLastOrPastLastDrawNumber = ((_competition$details2 = competition.details) === null || _competition$details2 === void 0 ? void 0 : _competition$details2.currentDrawNumber) >= lastDrawNumber;
  const getIsCompetitionFinished = () => {
    if (isLastOrPastLastDrawNumber) {
      if (draw) {
        return draw.drawStateId >= DrawState.Finalized;
      }
      return true;
    }
    return false;
  };
  if (isComplete || getIsCompetitionFinished()) {
    return FINISHED;
  } else if (isBeforeStartTime && isBeforeRegistrationStartTime) {
    return UPCOMING;
  } else if (!canRegister) {
    if (draw.drawStateId >= DrawState.Finalized) {
      return REG_CLOSED_DRAW_FINALIZED;
    } else if (draw.drawStateId >= DrawState.Regclosed) {
      return REG_CLOSED_DRAW_LIVE;
    } else if (draw.drawStateId >= DrawState.Opened) {
      return REG_CLOSED_DRAW_OPEN;
    } else if (draw.drawStateId >= DrawState.Defined) {
      return REG_CLOSED_DRAW_DEFINED;
    }
  } else if (canRegister) {
    if (!draw) {
      return REG_OPEN_DRAW_DEFINED;
    } else if (draw.drawStateId >= DrawState.Finalized) {
      return REG_OPEN_DRAW_FINALIZED;
    } else if (draw.drawStateId >= DrawState.Regclosed) {
      return REG_OPEN_DRAW_LIVE;
    } else if (draw.drawStateId >= DrawState.Opened) {
      return REG_OPEN_DRAW_OPEN;
    } else if (draw.drawStateId >= DrawState.Defined) {
      return REG_OPEN_DRAW_DEFINED;
    }
    return REG_OPEN_DRAW_OPEN;
  }
  return NO_COMPETITION; 
};
const toExport = {
  resolveCompetitionBackground,
  resolveCompetitionLogo,
  resolveCompetitionName,
  resolveCompetitionUrl,
  resolveCompetitionDrawStatus
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.marketplaceCompetition.helpers', toExport);
}

 })(window);